<template>
    <v-app class="app">
        <v-layout class="layout">
            <div class="column">
                <div class="column-container">
                    <div class="header">
                        <shared-common-logo/>
                    </div>
                </div>
            </div>
            <div class="main">
                <div class="main-container">
                    <nuxt-page/>
                </div>

            </div>

            <div class="column"/>
            <shared-base-notification/>

        </v-layout>
        <div class="footer">
            <shared-common-footer/>
        </div>
    </v-app>
</template>

<style scoped lang="scss">
    .app {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        flex: 1 0 auto;
        background-color: $main-bg-color;

        :deep(.v-application__wrap) {
            justify-content: center;
            align-items: center;
            align-self: stretch;
        }
    }

    .layout {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        flex: 1 0 auto;

        @include responsive( 'mobile' ) {
            flex-direction: column;
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        flex-shrink: 0;

        @include responsive( 'mobile' ) {
            width: inherit;
            padding: 0;
        }
        @include responsive( 'desktop' ) {
            width: 240px;
            padding: 60px var(--Padding-pa-6, 24px);
        }
        @include responsive( 'desktop-small' ) {
            padding: 80px 40px;
            width: 360px;
        }

        .column-container {
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            align-items: flex-start;
            align-self: stretch;
            gap: 120px;

            @include responsive( 'mobile' ) {
                justify-content: center;
                align-items: center;
                flex: inherit;
                gap: 0;
            }

            .header {
                @include responsive('mobile') {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-self: stretch;
                    padding: 20px 12px;
                }

                .logo {
                    display: flex;
                    padding: 0px 12px;
                    align-items: flex-start;
                    gap: var(--Padding-pa-6, 24px);
                    color: $logo-color;
                }
            }
        }
    }

    .main {
        display: flex;
        padding: var(--Padding-pa-8, 32px) var(--Padding-pa-6, 24px);

        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        gap: var(--Padding-pa-8, 32px);
        flex: 1 0 0;

        @include responsive( 'desktop' ) {
            max-width: 500px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .main-container {
            display: flex;
            flex-direction: column;
            padding: var(--Padding-pa-10, 40px) var(--Padding-pa-20, 80px) var(--Padding-pa-20, 80px) 0;
            gap: var(--Padding-pa-10, 40px);
            flex: 1 0 0;
            align-self: stretch;
            align-items: flex-start;
            justify-content: flex-start;

            @include responsive( 'mobile' ) {
                max-width: 640px;
                padding: 32px var(--Padding-pa-6, 24px);
            }
            @include responsive( 'desktop' ) {
                padding: var(--Padding-pa-6, 24px) 32px;
                gap: var(--Padding-pa-6, 24px);
            }
            @include responsive( 'desktop-small' ) {
                padding: 40px;
            }
            @include responsive( 'desktop-medium' ) {
                padding: 40px 80px;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        gap: 16px;
        padding: var(--Padding-pa-8, 32px) var(--Padding-pa-6, 24px);
    }
</style>
